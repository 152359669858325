import { render, staticRenderFns } from "./ServiceAutodiscovery.vue?vue&type=template&id=2437f86c&scoped=true&"
import script from "./ServiceAutodiscovery.vue?vue&type=script&lang=js&"
export * from "./ServiceAutodiscovery.vue?vue&type=script&lang=js&"
import style0 from "./ServiceAutodiscovery.vue?vue&type=style&index=0&id=2437f86c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2437f86c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VCard,VIcon,VList,VListItem,VListItemAction,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VSubheader})
