<template>
  <div class="warehouse">
    <Breadcrumb :items="breadCrumbs" :key="category.categoryId" />
    <v-container fluid>
      <service-autodiscovery />
      <v-row no-gutters class="primary warehouses-number"
        ><v-col cols="12" md="7">
          <v-img
            v-if="headerImage"
            dark
            :src="headerImage"
            class="elevation-0 rounded-0"
          >
          </v-img
        ></v-col>
        <v-col class="primary lighten-1 pa-5 pa-md-0">
          <div
            class="d-flex flex-column align-center justify-start h-100 white--text"
          >
            <div class="number">
              {{ warehouseNumber }}
            </div>
            <div class="sub text-center">
              {{ $t("storeLocator.warehouseNumbersSub") }}
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="text-center">
        <h2
          v-if="!category.metaData.category_info.HIDE_TITLE"
          class="primary--text text--lighten-1 mt-10 font-weight-bold"
        >
          {{ title }}
        </h2>
        <div v-html="description"></div>
      </div>

      <v-row class="regions">
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="province in category.children"
          :key="province.categoryId"
        >
          <v-btn
            :to="{
              name: 'WarehouseRegion',
              params: { region: province.slug }
            }"
            color="white"
            x-large
            depressed
            block
            class="btn-region text-none"
          >
            <span>{{ province.name }}</span>
            <v-spacer />
            <v-icon small>$chevronRight</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Breadcrumb from "@/components/navigation/Breadcrumb";

import category from "~/mixins/category.js";
import categoryTitle from "@/mixins/categoryTitle.js";
import ServiceAutodiscovery from "@/components/store/ServiceAutodiscovery.vue";

import AddressService from "~/service/addressService";

export default {
  name: "StoreLocator",
  components: { Breadcrumb, ServiceAutodiscovery },
  mixins: [category, categoryTitle],
  data() {
    return {
      warehouseNumber: 0
    };
  },
  methods: {
    async findWarehouse() {
      let data = await AddressService.findWarehouse();
      this.warehouseNumber = data.warehouses.length;
    }
  },
  computed: {
    breadCrumbs() {
      let breadCrumbs = [
        { to: { name: "Home" }, text: "Homepage", exact: true }
      ];
      if (this.category) {
        breadCrumbs.push({
          to: {
            name: "WarehouseLocator"
          },
          text: "Città servite",
          exact: true
        });
      }
      return breadCrumbs;
    }
  },
  mounted() {
    this.findWarehouse();
  }
};
</script>
